var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userList,"items-per-page":5,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/static/images/avatars/" + (item.avatar)))}}):_c('span',[_vm._v(_vm._s(item.full_name.slice(0,2).toUpperCase()))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.full_name))]),_c('small',[_vm._v(_vm._s(item.post))])])],1)]}},{key:"item.salary",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("$" + (item.salary)))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusColor[_vm.status[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('p',{staticClass:"my-1"},[_vm._v(" City: "+_vm._s(item.city)+" ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" Experience: "+_vm._s(item.experience)+" ")]),_c('p',[_vm._v("Post: "+_vm._s(item.post))])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }